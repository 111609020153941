import Typography from "@mui/material/Typography";
import TYPE_STRINGS from "../../static/constants/TYPE_STRINGS";
import {
  unitLargeAbbr,
  unitSmallAbbr,
} from "../../static/constants/systems_of_measurement";
import { roundNumber, unitWrapper } from "../utils";
import { getUnits, unitFeet, unitPerMile } from "./unitConversions";

export default function ResourceDetails({ dialogData }) {
  const units = getUnits();
  return (
    dialogData?.battery_capacity && (
      <div>
        <div style={{ marginBottom: "1%" }}>
          <Typography component="span" align="left" noWrap className="boldText">
            MODEL:
          </Typography>
          <Typography component="span" align="center" noWrap>
            {dialogData["model"]}
          </Typography>
        </div>
        <div style={{ marginBottom: "1%" }}>
          <Typography component="span" align="left" noWrap className="boldText">
            TYPE:
          </Typography>
          <Typography component="span" align="center" noWrap>
            {TYPE_STRINGS.VEHICLE_TYPE[parseInt(dialogData.type)]}
          </Typography>
        </div>
        <div style={{ marginBottom: "1%" }}>
          <Typography component="span" align="left" noWrap className="boldText">
            SIZE:
          </Typography>
          <Typography component="span" align="center" noWrap>
            {dialogData.type == 1 ? (
              <>
                {unitFeet(dialogData.size)}
                {unitWrapper(unitSmallAbbr[units])}
              </>
            ) : dialogData.type == 4 ? (
              `Type ${dialogData.size}`
            ) : dialogData.size == 14 ? (
              "Transit"
            ) : (
              `Class ${dialogData.size}`
            )}
          </Typography>
        </div>
        <div style={{ marginBottom: "1%" }}>
          <Typography component="span" align="left" noWrap className="boldText">
            BATTERY CAPACITY:
          </Typography>
          <Typography component="span" align="center" noWrap>
            {dialogData?.battery_capacity} {unitWrapper("kW")}
          </Typography>
        </div>
        <div style={{ marginBottom: "1%" }}>
          <Typography component="span" align="left" noWrap className="boldText">
            DEFAULT EFFICIENCY:
          </Typography>
          <Typography component="span" align="center" noWrap>
            {roundNumber(unitPerMile(dialogData["efficiency"]))}{" "}
            {unitWrapper(`kW/${unitLargeAbbr[units]}`)}
          </Typography>
        </div>
        <div style={{ marginBottom: "1%" }}>
          <Typography component="span" align="left" noWrap className="boldText">
            PASSENGER CAPACITY:
          </Typography>
          <Typography component="span" align="center" noWrap>
            {dialogData["passenger_capacity"]}
          </Typography>
        </div>
      </div>
    )
  );
}
