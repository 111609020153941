import FilterAlt from "@mui/icons-material/FilterAlt";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Chip,
  Container,
  InputAdornment,
  MenuItem,
  Paper,
  Popover,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import TYPE_STRINGS from "../../static/constants/TYPE_STRINGS";
import { unitSmallMap } from "../../static/constants/systems_of_measurement";
import { getUnits, unitFeet } from "../secondary/unitConversions";
import { getLocalData } from "../utils.js";
import { status_label_lookup } from "./graphs/vehicleActivityTimeTable.js";

/**
 * renders a dropdown of vehicle types and sizes contained in a dataset.
 * All duplicate type/size combos are removed
 * @param {Object} param0
 * @param {{veh_type: number, size: string, vehicleModel: string}[]} param0.data
 * @param {string} selectedModel string is of vehicleModel or "all"
 */
function VehicleModelDropdown({ data, selectedModel, setSelectedModel }) {
  const units = getUnits();

  //creates an object of vehicle models mapped to labels, only needs to be recalculated on data changes (i.e. if data updates during initialization)
  const modelOptions = useMemo(() => {
    const modelOptions = { all: "All Vehicles" };
    data.forEach((row) => {
      if (!(row.vehicleModel in modelOptions))
        modelOptions[row.vehicleModel] =
          `${TYPE_STRINGS.VEHICLE_TYPE[row.veh_type]}: ` +
          (row.veh_type == 1
            ? `${unitFeet(row.size)}${unitSmallMap[units]}`
            : row.veh_type == 4
            ? `Type ${row.size}`
            : row.size == 14
            ? "Transit"
            : `Class ${row.size}`);
    });
    return modelOptions;
  }, [data]);

  return (
    <TextField
      size="small"
      variant="outlined"
      value={selectedModel}
      onChange={(e) => setSelectedModel(e.target.value)}
      sx={{ m: "0.5rem" }}
      select
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FilterAlt />
          </InputAdornment>
        ),
      }}
    >
      {Object.entries(modelOptions).map(([value, label]) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
}

function LegendChip({ data = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const legendItems = useMemo(() => {
    const legendObject = {};
    //make an object of only the status values that exist in data (so that legend does not contain unnecessary entries)
    data.forEach(({ profile }) =>
      profile.forEach(({ status }) => {
        if (!(status in legendObject)) {
          legendObject[status] = status_label_lookup[status];
        }
      })
    );

    //idle init and idle_post_charging have same color and label, and thus should not both be shown in legend at the same time
    if ("idle_init" in legendObject && "idle_post_charging" in legendObject)
      delete legendObject.idle_init;

    return Object.values(legendObject); //convert object into list of labels and colors for legend mapping
  }, [data]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <span onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
      <Chip label="Legend" />
      <Popover
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Paper sx={{ py: 2, pointerEvents: "auto" }} elevation={3}>
          <Container fixed maxWidth="xl">
            {legendItems.map(({ label, color }) => (
              <span key={label}>
                <canvas
                  width="13"
                  height="13"
                  style={{ borderColor: "white", backgroundColor: color }}
                />
                &nbsp;
                {label.replaceAll(" ", "\xa0")}
                <br />
              </span>
            ))}
          </Container>
        </Paper>
      </Popover>
    </span>
  );
}

/**
 *
 * @param {import("@mui/lab").LoadingButtonProps} param0
 * @returns {import("@mui/material").ExtendButton<import("@mui/lab").LoadingButtonTypeMap>}
 */
function NextPageButton({ children, ...props }) {
  const [isLocked, setIsLocked] = useState(true);

  useEffect(() => {
    function fetchLockedStatus() {
      getLocalData("simulation", "data").then(({ data }) =>
        setIsLocked(data?.locked)
      );
    }

    fetchLockedStatus();
  }, []);

  return (
    <LoadingButton
      variant="contained"
      className="btn"
      {...props}
      disabled={Boolean(isLocked || props?.disabled)}
      endIcon={isLocked ? undefined : props?.endIcon}
    >
      {isLocked ? <>Analysis is Locked</> : children}
    </LoadingButton>
  );
}

export { LegendChip, NextPageButton, VehicleModelDropdown };
