import MaterialTable, { MTableToolbar } from "@material-table/core";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { memo, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { DataContext } from "../../../../contexts/dataContext";
import { localDb } from "../../../../contexts/localDb";
import { SnackBarContext } from "../../../../contexts/snackBarContext";
import TYPE_STRINGS from "../../../../static/constants/TYPE_STRINGS";
import {
  simulationURL,
  tcoURL,
} from "../../../../static/constants/backendRoutes";
import { defaultFinancialParamInput } from "../../../../static/constants/defaultInputs";
import stepInfo from "../../../../static/constants/stepInfo";
import {
  unitLargeAbbr,
  unitSmallMap,
  unitVolumeMap,
} from "../../../../static/constants/systems_of_measurement";
import UseAuth from "../../../auth/useAuth";
import { AssessmentAnalysisStepper } from "../../../secondary/steppers";
import {
  currencyDisplay,
  currencySymbol,
  getUnits,
  unitFeet,
  unitGallons,
  unitMiles,
  unitMoney,
  unitPerGallon,
  unitPerMile,
  unitPerMoney,
} from "../../../secondary/unitConversions";
import {
  Icons,
  errorHandler,
  getLocalData,
  getMasterData,
  roundNumber,
  storeLocalData,
  unitWrapper,
} from "../../../utils";
import { NextPageButton } from "../../commonComponents";
import SimulationSubtitle from "../../dialogs/simulationSubtitle";
import { VehicleDataComponent } from "../../tables/financialAnalysisTable";

const STEP_NUMBER = 6;

const vehicleConstants = {
  constants: {
    BEVeff_Num: 1,
    ICEeff_Num: 0.2,
    oneGalEq_kWh: 40,
    ICEMpg: 4,
  },
};

const chipMap = [
  `Previous Step: ${stepInfo[STEP_NUMBER - 1].label}`,
  "Conventional Vehicle",
  "Battery Electric Vehicle",
  "Charger",
  "Infrastructure Upgrade",
  "Energy",
  "Grid Emissions",
  "Fuel",
  `Run ${stepInfo[STEP_NUMBER].label} Analysis`,
];

export { chipMap as financialChipMap };

export default function FinancialAnalysis() {
  const [open, setOpen] = useState(false);
  const [goBackDaialog, setGoBackDialog] = useState(false);
  //todo: consider combining vehicle, charger, and energyChip data into a single object
  const [vehicleChipData, setVehicleChipData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [chargerChipData, setChargerChipData] = useState([]);
  const [energyChipData, setEnergyChipData] = useState([]);
  const [dataFetchError, setDataFetchError] = useState(false);
  const [vehicles, setVehicles] = useState([]); // used for the vehicle model info card pop-up in battery Electric vehicle chip

  /** @type {[{ interest: Number, depreciation: Number, discount: Number, energySaved: Boolean, fuelSaved: Boolean, energyCosts: {energy: Number, demand: Number}[][], fuelCosts: {ICE: Number, PROP: Number, CNG: Number, GAS: Number}[]}]} */
  const [financialInputs, setFinancialInputs] = useState({
    ...defaultFinancialParamInput,
    energyCosts: Array(12)
      .fill()
      .map(() => Array(24).fill({ energy: 0.0, demand: 0.0 })),
    energySaved: "yearly",
    fuelCosts: Array(12).fill({ ICE: 4.17, PROP: 3.29, CNG: 2.85, GAS: 2.85 }),
    fuelSaved: "yearly",
  });

  const [selectedChip, setSelectedChip] = useState(1); // 1 for Battery Electrict Vehicle, 2 for convenional vehicle, 3 for Charger, 5 for Energy

  const { accessRights } = useContext(DataContext);
  const { snackBarElement } = useContext(SnackBarContext);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        if (!UseAuth("get")) {
          window.location.assign("/login");
          return;
        }
        //gets the financial data from the indexdb
        const financialValues = await getLocalData("financial");
        const financialData = financialValues?.data;
        const financialParamInputs = financialValues?.input;
        setFinancialInputs({ ...financialInputs, ...financialParamInputs });

        const vehicleTableData = financialData?.vehicle || [];
        const chargerTableData = financialData?.charger || [];
        //note: iucChipData and emissionData are both part of energyData
        const energyTableData = financialData?.energy || [];

        getMasterData(snackBarElement, {
          modles: vehicleTableData,
          onSuccess: setVehicles,
        });
        setVehicleChipData(vehicleTableData);
        setChargerChipData(chargerTableData);
        setEnergyChipData(energyTableData);
        setDataFetchError(true);
      } catch (e) {
        console.log(e);
        setDataFetchError(true);
      }
    }

    fetchData();
  }, []);

  const runTCO = async () => {
    setButtonLoading(true);
    try {
      const [{ data: evAssessmentData }, { input: routeEnergyInputs }] =
        await Promise.all([
          getLocalData("evAssessment", "data"),
          getLocalData("routeEnergy", "input"),
        ]);

      if (
        !evAssessmentData ||
        (Object.keys(evAssessmentData).length === 0 &&
          evAssessmentData.constructor === Object)
      ) {
        //if the localDb retrieval failed to get the routeEnergyPageSelectedRows
        snackBarElement.current.displayToast(
          "Couldn't find local evAssessmentData, please go back a page and try again",
          "error",
          10000
        );
        console.log("couldn't find the previous page's selected rows");
        setButtonLoading(false);
      } else {
        const body = {
          capitalCost: {
            years: [
              {
                year: 0,
                vehicles: vehicleChipData.map((vehicle) => ({
                  model: vehicle.model,
                  ICE: { vehCost: vehicle.ice_veh_cost },
                  BEV: {
                    vehCost: vehicle.bev_veh_cost,
                    BatteryCost: vehicle.batteryRepCost,
                    BatteryResale: vehicle.batteryResValue,
                    incentive: vehicle.incentiveVeh, // hard-coded to 0
                    subsidy: 0,
                  },
                })),
                chargers: chargerChipData.map((charger) => ({
                  model: charger.model,
                  cost: charger.cost,
                  subsidy: 0,
                  incentive: charger.incentiveCharger, // hard-coded to 0
                  installAndCommission: charger.incCost,
                })),
                other: {
                  infra: { cost: energyChipData[0].infraUpCost },
                },
              },
            ],
          },
          operatingCost: {
            years: [
              {
                year: 0,
                vehicles: [
                  {
                    type: "BEV",
                    maintenance: vehicleChipData[0].maintenanceVehBEV,
                  },
                  {
                    type: "ICE",
                    maintenance: vehicleChipData[0].maintenanceVehICE,
                  },
                ],
                chargers: {
                  chargerOM: chargerChipData[0].chargerOM,
                },
                other: {},
              },
            ],
          },
          fleetAndCharger: {
            general: {
              vehicles: evAssessmentData.daily_total_distance.map((ev) => ({
                model: ev.vehicle_model,
                energyUsageperDay:
                  evAssessmentData.daily_energy_consumption.find(
                    (eConsump) => eConsump.vehicle_model == ev.vehicle_model
                  ).nec,
                BEV: {
                  avgDistancePerDay: ev.distance,
                  operationDaysPerYear: 300,
                  vehLife: routeEnergyInputs.operating_year,
                  batLife: routeEnergyInputs.operating_year,
                },
                ICE: {
                  avgDistancePerDay: ev.distance,
                  operationDaysPerYear: 300,
                  vehLife: routeEnergyInputs.operating_year,
                },
              })),
              chargers: chargerChipData.map((charger) => ({
                model: charger.model,
                chargerLife: routeEnergyInputs.operating_year,
              })),
            },
            years: [
              {
                year: 0,
                vehicles: evAssessmentData.fleet_size.breakdown.reduce(
                  (prev, curr) =>
                    prev.concat([
                      {
                        type: "BEV",
                        model: curr.vehicle_model,
                        count: curr.ev_fleet_size,
                      },
                      {
                        type: "ICE",
                        model: curr.vehicle_model,
                        count: curr.ice_fleet_size,
                      },
                    ]),
                  []
                ),
                chargers: [
                  //in the future, DEFINITELY MaYbE possibly (sure) allow more than one
                  {
                    model: evAssessmentData.selectedCharger,
                    count: evAssessmentData.num_chargers,
                  },
                ],
              },
            ],
          },
          finance: {
            interest: financialInputs.interest,
            discount: financialInputs.discount,
            depreciation: financialInputs.depreciation,
          },
          constants: vehicleConstants.constants,
          emissions: {
            CO2: energyChipData[0].gridEmission,
          },
          power: [
            {
              type: "Uncontrolled",
              profile: evAssessmentData.load_profile["full_power"],
              maxPower: roundNumber(
                Math.max(...evAssessmentData.load_profile["full_power"])
              ),
            },
            {
              type: "low",
              profile: evAssessmentData.load_profile["low_power"],
              maxPower: roundNumber(
                Math.max(...evAssessmentData.load_profile["low_power"])
              ),
            },
          ],
          energy: [
            {
              type: "fuel",
              cost: energyChipData[0].dieselCost,
              annualEsc: 0, // hardcoded as 0
            },
            {
              type: "electricity",
              cost: energyChipData[0].elecCostEnergy,
              annualEsc: 0,
            },
            {
              type: "demand",
              cost: energyChipData[0].elecCostDemand,
              annualEsc: 0,
            },
          ],
        };
        const { data: sim } = await getLocalData("simulation", "data");

        let headers = {
          Authorization: `Token ${UseAuth("get")}`,
          "Content-Type": "application/json",
          Accept: `application/json; version=${sim.analysis_type_steps.financial_and_emissions.tco}`,
        };

        await fetch(tcoURL, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        }).then((response) => {
          delete headers["Accept"];
          if (response.ok) {
            return response.json().then(async (tcoData) => {
              storeLocalData("tco", { data: tcoData });
              //update the financial collection in indexDb
              ///Note that this put doesn't do much other than handle any selection changes, as all other input/data changes are saved to localDb as they are made
              storeLocalData("financial", {
                data: {
                  vehicle: vehicleChipData,
                  charger: chargerChipData,
                  energy: energyChipData,
                },
                input: financialInputs,
              });

              const backendBody = {
                id: sim.id,
                completed: true,
                current_page: stepInfo[STEP_NUMBER + 1].route,
                steps: {
                  tco: tcoData,
                  financial: {
                    vehicle: vehicleChipData,
                    charger: chargerChipData,
                    energy: energyChipData,
                  },
                  input: {
                    financial: {
                      interest: financialInputs.interest,
                      discount: financialInputs.discount,
                      depreciation: financialInputs.depreciation,
                    },
                  },
                },
              };

              fetch(simulationURL, {
                method: "PATCH",
                headers: headers,
                body: JSON.stringify(backendBody),
              }).then((response) => {
                if (response.ok) {
                  snackBarElement.current.displayToast(
                    `${stepInfo[STEP_NUMBER].label} Analysis Complete`
                  );

                  navigate(stepInfo[STEP_NUMBER + 1].route);
                } else {
                  errorHandler(
                    response,
                    snackBarElement,
                    "Failed to Update Simulation backend"
                  );
                  setButtonLoading(false);
                }
              });
            });
          } else {
            errorHandler(
              response,
              snackBarElement,
              "Something went wrong, try again later"
            );
            setButtonLoading(false);
          }
        });
      }
    } catch (e) {
      snackBarElement.current.displayToast(
        "Something went wrong, try again later",
        "error"
      );
      console.log("unknown error", e);
      setButtonLoading(false);
    }
  };

  /** updates the financial params, and then closes the associated
   * financial params dialog box
   */
  const handleUpdateFinancialInputs = (event) => {
    event.preventDefault();
    const params = {
      interest: document.getElementById("interest").valueAsNumber / 100,
      discount: document.getElementById("discount").valueAsNumber / 100,
      // depreciation: document.getElementById("depreciation").valueAsNumber,
      depreciation: 0,
    };
    setFinancialInputs(params);
    storeLocalData("financial", { input: params });
    setOpen(false);
  };

  const nextButtonClicked = () => {
    if (selectedChip === 4) {
      runTCO();
      return;
    }
    setSelectedChip((prev) => prev + 1);
  };

  const prevButtonClicked = () => {
    if (selectedChip === 1) {
      setGoBackDialog(true);
      return;
    }

    setSelectedChip((prev) => prev - 1);
  };

  const buttonTitle = (stepNum) => {
    let titleObj = { next: "", prev: "" };
    switch (stepNum) {
      case 1:
        titleObj.next = "Battery Electric Vehicle";
        titleObj.prev = `Previous Step: ${stepInfo[STEP_NUMBER - 1].label}`;
        return titleObj;
      case 2:
        titleObj.next = "Charger";
        titleObj.prev = "Conventional Vehicle";
        return titleObj;
      case 3:
        titleObj.next = "Energy";
        titleObj.prev = "Battery Electric Vehicle";
        return titleObj;
      case 4:
        titleObj.next = `Run ${stepInfo[STEP_NUMBER].label} Analysis`;
        titleObj.prev = "Charger";
        return titleObj;
    }
  };

  return (
    <div>
      <br />
      <br /> <AssessmentAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      <br />
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          {/* replaces all spaces with non-breakling space equivalents */}
          {stepInfo[STEP_NUMBER].label.replaceAll(" ", "\xa0")}
        </Typography>
        <SimulationSubtitle />
      </Container>
      <br />
      <Container fixed maxWidth="xl">
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            minHeight: "280px",
            paddingX: "8px",
          }}
          elevation={3}
        >
          <MemoizedEnergyTableV1
            vehicles={vehicles}
            dataFetchError={dataFetchError}
            setVehicleChipData={setVehicleChipData}
            setChargerChipData={setChargerChipData}
            setEnergyChipData={setEnergyChipData}
            energyChipData={energyChipData}
            chargerChipData={chargerChipData}
            vehicleChipData={vehicleChipData}
            selectedChip={selectedChip}
            setSelectedChip={setSelectedChip}
          />
        </Paper>
      </Container>
      <br />
      <Container
        fixed
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Stack
          direction="column"
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={1}
          sx={{ width: "100%" }}
        >
          <Grid container justifyContent="flex-start">
            <Grid item xs={12} sm={6} md={6}>
              <Button
                className="btn"
                color="primary"
                sx={{ width: "95%" }}
                onClick={prevButtonClicked}
                variant="outlined"
                startIcon={<ArrowBackIosNew />}
              >
                {buttonTitle(selectedChip).prev}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Button
                color="primary"
                variant="outlined"
                className="btn"
                sx={{ width: "95%" }}
                onClick={() => setOpen(true)}
              >
                Financial Parameters
              </Button>
            </Grid>
          </Grid>
          <NextPageButton
            onClick={nextButtonClicked}
            loading={buttonLoading}
            endIcon={<ArrowForwardIos />}
            disabled={
              !chargerChipData.length ||
              !vehicleChipData.length ||
              (selectedChip == 4 &&
                !accessRights.analysis.create_financial_and_emissions_analysis)
            }
          >
            {buttonTitle(selectedChip).next}
          </NextPageButton>
        </Stack>
      </Container>
      <br />
      <br />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Financial Parameters</DialogTitle>
        <Box
          component="form"
          onSubmit={handleUpdateFinancialInputs}
          sx={{ mt: 2 }}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Tooltip
                  title={
                    <div style={{ textAlign: "center" }}>
                      {" "}
                      Interest Rate from Capital Financing
                      <br />
                      (if Applicable)
                    </div>
                  }
                >
                  <TextField
                    required
                    fullWidth
                    id="interest"
                    label="Interest Rate"
                    defaultValue={roundNumber(financialInputs.interest * 100)}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0.0, step: "1" },
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    autoFocus
                    disabled={
                      !accessRights.analysis
                        .create_financial_and_emissions_analysis
                    }
                  />
                </Tooltip>{" "}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="discountRate"
                  required
                  fullWidth
                  id="discount"
                  label="Discount Rate"
                  defaultValue={roundNumber(financialInputs.discount * 100)}
                  type="number"
                  InputProps={{
                    inputProps: { min: 0.0, step: "1" },
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  disabled={
                    !accessRights.analysis
                      .create_financial_and_emissions_analysis
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog open={goBackDaialog} onClose={() => setGoBackDialog(false)}>
        <DialogContent>
          This will take you back to the previous step of{" "}
          {stepInfo[STEP_NUMBER - 1].label}. All your current changes will be
          undone if you go back.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGoBackDialog(false)}>Cancel</Button>
          <Button
            type="submit"
            component={Link}
            to={stepInfo[STEP_NUMBER - 1].route}
          >
            Yes, go back
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const MemoizedEnergyTableV1 = memo(
  ({
    vehicles,
    dataFetchError,
    setVehicleChipData,
    setChargerChipData,
    setEnergyChipData,
    energyChipData,
    chargerChipData,
    vehicleChipData,
    selectedChip,
    setSelectedChip,
  }) => {
    const { accessRights } = useContext(DataContext);
    const [editRow, setEditRow] = useState({});
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const units = getUnits();

    const chipMap = {
      1: "Conventional Vehicle",
      2: "Battery Electric Vehicle",
      3: "Charger",
      4: "Energy",
    };

    function addColumn(selectedChip) {
      let tableObj = { data: [], columns: [], editableColToShow: [] };

      switch (selectedChip) {
        case 1:
          tableObj.data = vehicleChipData;
          tableObj.columns = conventionalVehicleColumn;
          break;
        case 2:
          tableObj.data = vehicleChipData;
          tableObj.columns = vehicleBEVColumn;
          break;
        case 3:
          tableObj.data = chargerChipData;
          tableObj.columns = chargerColumn;
          break;
        case 4:
          tableObj.data = energyChipData;
          tableObj.columns = energyColumn;
          break;
      }

      return tableObj;
    }

    /**
     * @returns {jsx} the edit row dialog box pop-up
     */
    function EditableFields() {
      const bevVehicleEditableCol = [
        {
          title: "Vehicle Unit Cost",
          name: "ice_veh_cost",
          type: "currency",
          value: editRow.ice_veh_cost,
        },
        {
          title: `Maintenance (${currencySymbol()}/${
            unitLargeAbbr[units]
          }) (ICE)`,
          name: "maintenanceVehICE",
          type: "currencyPerMile",
          value: editRow.maintenanceVehICE,
        },
      ];

      const conventionalVehicleEditableColumn = [
        {
          title: "Unit Cost",
          name: "bev_veh_cost",
          type: "currency",
          value: editRow.bev_veh_cost,
        },
        {
          title: "Incentives",
          name: "incentiveVeh",
          type: "currency",
          value: editRow.incentiveVeh,
        },
        {
          title: `Maintenance (${currencySymbol()}/${
            unitLargeAbbr[units]
          }) (BEV)`,
          name: "maintenanceVehBEV",
          type: "currencyPerMile",
          value: editRow.maintenanceVehBEV,
        },
        {
          title: "Battery Replacement Cost",
          name: "batteryRepCost",
          type: "currency",
          value: editRow.batteryRepCost,
        },
        {
          title: "Battery Resale Value",
          name: "batteryResValue",
          type: "currency",
          value: editRow.batteryResValue,
        },
      ];

      const chargerEditableCol = [
        {
          title: "Hardware Cost",
          name: "cost",
          type: "currency",
          value: editRow.cost,
        },
        {
          title: `maintenance (${currencySymbol()}/year)`,
          name: "chargerOM",
          type: "currency",
          value: editRow.chargerOM,
        },
        {
          title: "Installation and Commissioning Cost",
          name: "incCost",
          type: "currency",
          value: editRow.incCost,
        },
        {
          title: "Incentives",
          name: "incentiveCharger",
          type: "currency",
          value: editRow.incentiveCharger,
        },
      ];

      const energyEditableCol = [
        {
          title: "Infrastructure Upgrade Cost",
          name: "infraUpCost",
          type: "currency",
          value: editRow.infraUpCost,
        },
        {
          title: `Electricity Cost for Energy Charges (${currencySymbol()}/kWh)`,
          name: "elecCostEnergy",
          type: "currency",
          value: editRow.elecCostEnergy,
        },
        {
          title: `Electricity Cost for Demand Charges (${currencySymbol()}/kW)`,
          name: "elecCostDemand",
          type: "currency",
          value: editRow.elecCostDemand,
        },
        {
          title: `Grid Emissions - CO2e (kg/kWh)`,
          name: "gridEmission",
          type: "kgPerkWh",
          value: editRow.gridEmission,
        },
        {
          title: `Fuel Cost (Cost per ${unitVolumeMap[units]})`,
          name: "dieselCost",
          type: "currencyPerGallon",
          value: editRow.dieselCost,
        },
      ];

      const columnToShow =
        selectedChip === 1
          ? bevVehicleEditableCol
          : selectedChip === 2
          ? conventionalVehicleEditableColumn
          : selectedChip === 3
          ? chargerEditableCol
          : energyEditableCol;

      function typeGenerator(fieldType) {
        switch (fieldType) {
          case "currency":
          case "currencyPerMile":
          case "currencyPerGallon":
          case "kgPerkWh":
            return "number";
          default:
            return "text";
        }
      }

      function getChipData() {
        switch (selectedChip) {
          case 1:
          case 2:
            return [...vehicleChipData];
          case 3:
            return [...chargerChipData];
          case 4:
            return [...energyChipData];
        }
      }

      function defaultValue(element) {
        switch (element.type) {
          case "currencyPerMile":
            return roundNumber(unitMoney(unitPerMile(element.value)));
          case "currencyPerGallon":
            return roundNumber(unitMoney(unitPerGallon(element.value)));
          case "currency":
            return roundNumber(unitMoney(element.value));
          case "kgPerkWh":
            return roundNumber(element.value, 5);
          default:
            return element.value;
        }
      }

      let tempRow;
      function handleFieldUpdate(e, type) {
        let index = editRow.tableData.id;
        let attr = e.target.name;

        if (!tempRow) {
          const temp = getChipData();
          tempRow = { ...temp[index] };
        }

        switch (type) {
          case "currencyPerMile":
            tempRow[attr] = unitPerMoney(unitMiles(Number(e.target.value)));
            break;
          case "currencyPerGallon":
            tempRow[attr] = unitPerMoney(unitGallons(Number(e.target.value)));
            break;
          case "kgPerkWh":
            tempRow[attr] = Number(e.target.value);
            break;
          case "currency":
            tempRow[attr] = unitPerMoney(Number(e.target.value));
            break;
          default:
            tempRow[attr] =
              e.target.type === "number"
                ? Number(e.target.value)
                : e.target.value;
            break;
        }
      }

      function updateValue() {
        if (!tempRow) {
          setEditDialogOpen(false);
          return;
        }
        let index = editRow.tableData.id;
        let temp = getChipData();
        temp[index] = tempRow;
        if (selectedChip === 1 || selectedChip === 2) {
          //vehicleBEV
          setVehicleChipData(temp);
          localDb.financial.update("data", { "value.vehicle": temp });
        } else if (selectedChip === 3) {
          //charger
          setChargerChipData(temp);
          localDb.financial.update("data", { "value.charger": temp });
        } else if (selectedChip === 4) {
          //energy
          setEnergyChipData(temp);
          localDb.financial.update("data", { "value.energy": temp });
        }

        setEditDialogOpen(false);
      }

      return (
        <Dialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          maxWidth={"md"}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">
            Edit {chipMap[selectedChip]} details here
          </DialogTitle>
          <br />
          <DialogContent>
            <Container>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 5 }}
              >
                {columnToShow.map((el) => (
                  <Grid key={`Chip${selectedChip}_${el.name}`} item xs={6}>
                    <TextField
                      id={el.name}
                      name={el.name}
                      label={el.title}
                      variant="outlined"
                      fullWidth
                      type={typeGenerator(el.type)}
                      InputProps={{
                        startAdornment: (el.type.startsWith("currency") ||
                          undefined) && (
                          <InputAdornment position="start">
                            {currencySymbol()}
                          </InputAdornment>
                        ),
                        inputProps: el.type.startsWith("currency") && {
                          min: 0,
                          step: 0.01,
                        },
                      }}
                      defaultValue={defaultValue(el)}
                      onChange={(e) => handleFieldUpdate(e, el?.type)}
                      {...el?.props}
                    />
                  </Grid>
                ))}
              </Grid>
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)} autoFocus>
              Cancel
            </Button>
            <Button onClick={updateValue}>Done</Button>
          </DialogActions>
        </Dialog>
      );
    }

    const vehicleBEVColumn = [
      {
        title: "Vehicle Model",
        field: "model",
        render: (rowData) => (
          <VehicleDataComponent rowData={rowData} vehicles={vehicles} />
        ),
      },
      {
        title: "Unit Cost",
        field: "bev_veh_cost",
        render: (rowData) => <>{currencyDisplay(rowData.bev_veh_cost)}</>,
      },
      {
        title: (
          <>
            Incentive
            <br />
            (per Vehicle)
          </>
        ),
        field: "incentiveVeh",
        render: (rowData) => <>{currencyDisplay(rowData.incentiveVeh)}</>,
        width: "1%",
      },
      {
        title: (
          <>
            Maintenance
            <br />({currencySymbol()}/{unitLargeAbbr[units]})
          </>
        ),
        field: "maintenanceVehBEV",
        render: (rowData) => (
          <>{currencyDisplay(unitPerMile(rowData.maintenanceVehBEV))}</>
        ),
      },
      {
        title: "Battery Replacement Cost",
        field: "batteryRepCost",
        render: (rowData) => <>{currencyDisplay(rowData.batteryRepCost)}</>,
      },
      {
        title: "Battery Resale Value",
        field: "batteryResValue",
        render: (rowData) => <>{currencyDisplay(rowData.batteryResValue)}</>,
      },
    ];

    const conventionalVehicleColumn = [
      {
        title: "Vehicle Type",
        field: "type",
        render: (rowData) => (
          <div> {TYPE_STRINGS.VEHICLE_TYPE[parseInt(rowData.type)]}</div>
        ),
      },
      {
        title: "Vehicle Size",
        field: "size",
        render: (rowData) =>
          rowData.type == 1 ? (
            <>
              {unitFeet(rowData.size)} {unitWrapper(unitSmallMap[units])}
            </>
          ) : rowData.type == 4 ? (
            //if selected vehicle type is a schoolbus,remove the ft
            `Type ${rowData.size}`
          ) : rowData.size == 14 ? (
            "Transit"
          ) : (
            `Class ${rowData.size}`
          ),
      },
      {
        title: "Vehicle Unit Cost",
        field: "ice_veh_cost",
        render: (rowData) => <>{currencyDisplay(rowData.ice_veh_cost)}</>,
      },
      {
        title: (
          <>
            Maintenance
            <br />({currencySymbol()}/{unitLargeAbbr[units]})
          </>
        ),
        field: "maintenanceVehICE",
        render: (rowData) => (
          <>{currencyDisplay(unitPerMile(rowData.maintenanceVehICE))}</>
        ),
      },
    ];

    const chargerColumn = [
      { title: "Model", field: "model" },
      { title: "Rating (kW)", field: "rating" },
      {
        title: "Hardware Cost",
        field: "cost",
        render: (rowData) => <>{currencyDisplay(rowData.cost)}</>,
      },
      {
        title: (
          <>
            Incentives
            <br />
            (per Charger)
          </>
        ),
        field: "incentiveCharger",
        render: (rowData) => <>{currencyDisplay(rowData.incentiveCharger)}</>,
      },
      {
        title: `Maintenance (${currencySymbol()}/year)`,
        field: "chargerOM",
        render: (rowData) => <>{currencyDisplay(rowData.chargerOM)}</>,
      },
      {
        title: "Installation & Commissioning Cost",
        field: "incCost",
        render: (rowData) => <>{currencyDisplay(rowData.incCost)}</>,
      },
    ];

    const energyColumn = [
      {
        title: "Infrastructure Upgrade Cost",
        field: "infraUpCost",
        render: (rowData) => <>{currencyDisplay(rowData.infraUpCost)}</>,
      },
      {
        title: `Electricity Cost for energy charges (${currencySymbol()}/kWh)`,
        field: "elecCostEnergy",
        render: (rowData) => <>{currencyDisplay(rowData.elecCostEnergy)}</>,
      },
      {
        title: `Electricity cost for demand charges (${currencySymbol()}/kW)`,
        field: "elecCostDemand",
        render: (rowData) => <>{currencyDisplay(rowData.elecCostDemand)}</>,
      },
      {
        title: (
          <>
            Fuel Cost
            <br />
            (Cost/{unitVolumeMap[units]})
          </>
        ),
        field: "dieselCost",
        render: (rowData) => (
          <>{currencyDisplay(unitPerGallon(rowData.dieselCost))}</>
        ),
      },
      {
        title: `Grid Emission - CO2 Equivalent (kg/kWh)`,
        type: "numeric",
        render: (rowData) => roundNumber(rowData.gridEmission, 5),
      },
    ];

    vehicleBEVColumn.forEach((el) => {
      el.cellStyle = { textAlign: "center" };
    });

    conventionalVehicleColumn.forEach((el) => {
      el.cellStyle = { textAlign: "center" };
    });

    chargerColumn.forEach((el) => {
      el.cellStyle = { textAlign: "center" };
    });

    energyColumn.forEach((el) => {
      el.cellStyle = { textAlign: "center" };
    });

    return (
      <>
        <MaterialTable
          title={`${chipMap[selectedChip]} Cost Input`}
          columns={addColumn(selectedChip).columns}
          data={addColumn(selectedChip).data}
          icons={Icons()}
          localization={{
            body: {
              emptyDataSourceMessage: dataFetchError ? (
                // if an error occurs in resource fetch, display this message in the table
                "No records to display"
              ) : (
                // until the point that an error occurs or the data is retrieved, display a loading message in table
                <>
                  <CircularProgress />
                  <br />
                  Loading...
                </>
              ),
            },
            toolbar: {
              searchPlaceholder: "Filter",
              searchTooltip: "Filter",
            },
          }}
          options={{
            paging: false,
            pageSize: 20,
            showTextRowsSelected: false,
            headerStyle: { textAlign: "center" },
            search: false,
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ margin: "4px 10px" }}>
                  <Stack direction="row" spacing={1}>
                    {Object.entries(chipMap).map(([key, value]) => (
                      <Chip
                        label={value}
                        key={`${value}_chip`}
                        variant={key == selectedChip ? "filled" : "outlined"}
                        onClick={() => setSelectedChip(parseInt(key))}
                      />
                    ))}
                  </Stack>
                </div>
              </div>
            ),
          }}
          actions={[
            {
              icon: EditIcon,
              onClick: (event, rowData) => {
                setEditRow(rowData);
                setEditDialogOpen(true);
              },
              position: "row",
              tableLayout: "fixed",
              disabled:
                !accessRights.analysis.create_financial_and_emissions_analysis,
            },
          ]}
        />
        {/* the edit dialog box contents */}
        <EditableFields />
      </>
    );
  }
);
