import { FilterAlt } from "@mui/icons-material";
import {
  Chip,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useMemo, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { DataContext } from "../../../contexts/dataContext";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import { unitSmallMap } from "../../../static/constants/systems_of_measurement";
import { getUnits, unitFeet } from "../../secondary/unitConversions";
import { MFM_to_AMPM } from "../../utils";

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

/**
 *
 * @param {Object} param0
 * @param {{used_fleet_size_vs_time: {bev: Number[], ice: Number[], size: Number, type: Number}[]}[]} param0.data
 * @param {string|undefined} [param0.model="all"] the default selected model (format is size_type)
 * @param {import("chart.js").ChartOptions<"line">} [param0.chartOptions]
 */
export default function FleetSizingGraph({ data, model, chartOptions }) {
  const [selectedModel, setSelectedModel] = useState(model ?? "all");

  const { logo } = useContext(DataContext);

  const chartRef = useRef(null);

  const used_fleet_size_vs_time = data.map(
    ({ used_fleet_size_vs_time }) => used_fleet_size_vs_time
  );

  const upperLimit = Math.ceil(
    Math.max(
      ...data.map(({ block_fleet_growth_diff }) =>
        Math.max(...block_fleet_growth_diff.map(({ time }) => time))
      ),
      1441
    )
  );

  const isOneDay = upperLimit == 1441;

  const modelOptions = useMemo(() => {
    const units = getUnits();
    // have an empty array for the initial value
    let modelOptions = {
      all: {
        label: "All Vehicles",
        data: {
          bev: Array(upperLimit)
            .fill(0)
            .map((_row, i) =>
              used_fleet_size_vs_time.reduce((sum, val) => sum + val.bev[i], 0)
            ),
          ice: Array(upperLimit)
            .fill(0)
            .map((_row, i) =>
              used_fleet_size_vs_time.reduce((sum, val) => sum + val.ice[i], 0)
            ),
        },
      },
    };

    used_fleet_size_vs_time.forEach(
      (row) =>
        (modelOptions[`${row.size}_${row.type}`] = {
          label:
            `${TYPE_STRINGS.VEHICLE_TYPE[row.type]}: ` +
            (row.type == 1
              ? `${unitFeet(row.size)}${unitSmallMap[units]}`
              : row.type == 4
              ? `Type ${row.size}`
              : row.size == 14
              ? "Transit"
              : `Class ${row.size}`),
          data: row,
        })
    );

    return modelOptions;
  }, [data, model]);

  /** @type {import("chart.js").ChartData} */
  const chartData = {
    labels: Array.from(Array(upperLimit).keys()).map((MFM) =>
      isOneDay
        ? MFM_to_AMPM(MFM)
        : `${daysOfWeek[Math.floor(MFM / 1440)]} - ${MFM_to_AMPM(MFM)}`
    ),
    datasets: [
      {
        data: modelOptions[selectedModel].data.bev,
        label: "BEV Fleet Size",
        pointRadius: 0, //removes the circles that represent each point
        fill: false,
      },
      {
        data: modelOptions[selectedModel].data.ice,
        label: "ICE Fleet Size",
        pointRadius: 0, //removes the circles that represent each point
        fill: false,
      },
    ],
  };

  return (
    <div className="chartdiv">
      <Stack
        sx={{ justifyContent: "space-between", margin: "1%" }}
        direction="row"
        spacing={1}
      >
        <TextField
          size="small"
          variant="outlined"
          value={selectedModel}
          onChange={(e) => setSelectedModel(e.target.value)}
          sx={{ m: "0.5rem", display: model ? "none" : undefined }}
          select
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FilterAlt />
              </InputAdornment>
            ),
          }}
        >
          {Object.entries(modelOptions).map(([value, { label }]) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
        <Typography variant="h6" pr={10}>
          Required Fleet Size
        </Typography>
        <Chip
          label="Reset Zoom"
          onClick={() => chartRef?.current?.resetZoom()}
        />
      </Stack>
      <Line
        ref={chartRef}
        data={chartData}
        options={{
          interaction: { intersect: false, mode: "index" }, //has the tooltip appear when mouse is anywhere on map, instead of only above specific points
          scales: {
            x: {
              ticks: {
                callback: (val) => {
                  if (isOneDay)
                    //has x-axis tick labels only appear once every 3 hours (180 minutes)
                    return val % 180 === 0 ? MFM_to_AMPM(val) : null;
                  else return val % 1440 === 0 ? daysOfWeek[val / 1440] : null; //display days of week if limit is greater than 24 hours
                },
              },
              title: {
                display: true,
                text: isOneDay ? "Time of Day" : "Day of Week",
                font: { size: 16 },
              }, //label for x-axis
            },
            y: {
              title: {
                display: true,
                text: "Fleet Size",
                font: { size: 16 },
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                title: (value) => value[0].label,
                label: (value) =>
                  `${value.dataset.label}: ${value.formattedValue} Vehicles`, //customizes tooltip display for y-axis value
              },
            },
            zoom: {
              pan: { enabled: true, mode: "x", modifierKey: "ctrl" },
              zoom: { drag: { enabled: true }, mode: "x" },
            },
          },
          ...chartOptions,
        }}
      />
      <div
        style={{ right: "35px", bottom: "50px" }}
        className="watermark-container"
      >
        <img
          src={logo}
          width="auto"
          height="50px"
          className="watermark-img"
          alt="logo"
        />
      </div>
    </div>
  );
}
