import { MenuItem, TextField } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useMemo } from "react";

import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import { unitSmallMap } from "../../../static/constants/systems_of_measurement";
import { getUnits, unitFeet } from "../../secondary/unitConversions";
import { unitWrapper } from "../../utils";

/**
 * @param {Object} props
 * @param {{vehicleModel: string, veh_type: number, size: string, detailed_energy:{updated_efficiency:number} }[]} props.data
 * @param {import("material-react-table").RowSelectionState} rowSelection
 */
export default function RouteDefinitionGroupEdit({
  data,
  rowSelection,
  depotLookup,
  depot_type_size_lookup,
  validationErrors,
  setValidationErrors,
}) {
  const units = getUnits();

  //used to remove duplicate vehicle models
  const groupData = useMemo(() => {
    const dataLookup = {};
    data.forEach((row) => {
      const key = `${row.endDepot},${row.veh_type},${row.size}`;
      if (!(key in dataLookup))
        dataLookup[key] = {
          key: key,
          endDepot: row.endDepot,
          veh_type: row.veh_type,
          size: row.size,
          modelCount: 0,
          selectedCount: 0,
        };
      dataLookup[key].modelCount += 1;
      dataLookup[key].selectedCount += Boolean(rowSelection[row.id]);
    });

    return Object.values(dataLookup);
  }, [data]);

  /**
   *
   * @param {{cell: import("material-react-table").MRT_Cell<never, unknown>,column: import("material-react-table").MRT_Column<never, unknown>,row: import("material-react-table").MRT_Row<never>,table: import("material-react-table").MRT_TableInstance<never>}} param0
   * @returns {import("@mui/material").TextFieldProps}
   */
  const muiEditTextFieldPropsStandard = ({ table, row, cell }) => ({
    name: cell.id,
    error: !!validationErrors[cell.id],
    helperText: validationErrors[cell.id],
    onChange: (e) => {
      row._valuesCache[cell.column.id] = e.target.value;
      table.setEditingCell(cell);
    },
    onBlur: () =>
      setValidationErrors({ ...validationErrors, [cell.id]: undefined }),
  });

  const columns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */ () => {
      const vehTypeFilterOptionsSet = new Set(data.map((row) => +row.veh_type)); // create a set of all veh_types, for use in the filter of vehicle type dropdown
      return [
        {
          header: "Selected",
          accessorKey: "selectedCount",
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper("rows")}
            </>
          ),
          enableEditing: false,
        },
        {
          header: "Total",
          accessorKey: "modelCount",
          Cell: ({ cell }) => (
            <>
              {cell.getValue()} {unitWrapper("rows")}
            </>
          ),
          enableEditing: false,
        },
        {
          header: "Current Depot",
          accessorKey: "endDepot",
          Cell: ({ cell }) =>
            depotLookup[cell.getValue()]
              ? depotLookup[cell.getValue()]
              : "Loading...",
          filterVariant: "autocomplete",
          filterSelectOptions: Object.entries(depotLookup).map(
            ([value, label]) => ({ value, label })
          ),
          enableEditing: false,
        },
        {
          header: "Current Vehicle Type",
          accessorKey: "veh_type",
          Cell: ({ cell }) => TYPE_STRINGS.VEHICLE_TYPE[cell.getValue()],
          filterVariant: "autocomplete",
          filterSelectOptions: Object.entries(TYPE_STRINGS.VEHICLE_TYPE)
            .filter(([veh_type]) => vehTypeFilterOptionsSet.has(+veh_type))
            .map(([value, label]) => ({ value, label })),
          enableEditing: false,
          filterFn: "equals", // prevents fuzzy matches like "Box Truck" (2) = "Tractor Trailer" (12)
        },
        {
          header: "Current Size",
          accessorKey: "size",
          Cell: ({ row, cell }) =>
            row.getValue("veh_type") == 1 ? (
              <>
                {unitFeet(cell.getValue())} {unitWrapper(unitSmallMap[units])}
              </>
            ) : row.getValue("veh_type") == 4 ? ( //if selected vehicle type is not transit, hide the ft
              `Type ${cell.getValue()}`
            ) : cell.getValue() == 14 ? (
              "Transit"
            ) : (
              `Class ${cell.getValue()}`
            ),
          filterFn: (row, columnId, filterValue) =>
            (row.getValue("veh_type") == 1
              ? unitFeet(row.getValue(columnId))
              : row.getValue("veh_type") == 4
              ? `Type ${row.getValue(columnId)}`
              : row.getValue(columnId) == 14
              ? "Transit"
              : `Class ${row.getValue(columnId)}`
            )
              .toString()
              .indexOf(filterValue) != -1,
          enableEditing: false,
        },
        //VVV editable fields VVV
        {
          header: "Distance",
          id: ":distance",
          muiEditTextFieldProps: ({ cell, ...props }) => ({
            ...muiEditTextFieldPropsStandard({ cell, ...props }),
            type: "number",
            onBlur: () =>
              setValidationErrors({
                ...validationErrors,
                [cell.id]:
                  cell.getValue() != undefined && !(cell.getValue() > 0) //note: cell.id == ${row.index}_distance`
                    ? "Must be greater than 0"
                    : false,
              }),
          }),
          Cell: ({ row }) => (
            <TextField
              name={`${row.original.key}:distance`}
              fullWidth
              type="number"
              placeholder="Current"
            />
          ),
        },
        {
          header: "New Depot",
          id: ":endDepot",
          muiEditTextFieldProps: (props) =>
            muiEditTextFieldPropsStandard(props),
          editVariant: "select",
          editSelectOptions: [
            { label: <>{unitWrapper("Current")}</>, value: 0 },
            ...Object.entries(depotLookup).map(([value, label]) => ({
              value,
              label,
            })),
          ],
          Cell: ({ row }) => (
            <TextField
              name={`${row.original.key}:endDepot`}
              fullWidth
              defaultValue="unaltered"
              select //a Select-type textfield
              SelectProps={{
                MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
              }}
            >
              <MenuItem value={"unaltered"}>{unitWrapper("Current")}</MenuItem>
              {Object.entries(depotLookup).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          ),
        },
        {
          header: "New Vehicle Type",
          id: ":veh_type",
          muiEditTextFieldProps: (props) =>
            muiEditTextFieldPropsStandard(props),
          editVariant: "select",
          editSelectOptions: ({ row }) => {
            const depot = row.getValue(":endDepot") || row.getValue("endDepot");
            const options =
              depot in depot_type_size_lookup
                ? Object.keys(depot_type_size_lookup[depot]).map(
                    (veh_type) => ({
                      label: TYPE_STRINGS.VEHICLE_TYPE[veh_type],
                      value: veh_type,
                    })
                  )
                : [];
            return [
              { label: <>{unitWrapper("Current")}</>, value: 0 },
              ...options,
            ];
          },
          Cell: ({ row, cell }) => {
            console.log(row._valuesCache);
            return (
              <TextField
                name={`${row.original.key}:veh_type`}
                fullWidth
                defaultValue="unaltered"
                select //a Select-type textfield
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
                }}
              >
                <MenuItem value={"unaltered"}>
                  {unitWrapper("Current")}
                </MenuItem>
                {Object.keys(depot_type_size_lookup[row.original.endDepot]).map(
                  (veh_type) => (
                    <MenuItem key={veh_type} value={veh_type}>
                      {TYPE_STRINGS.VEHICLE_TYPE[veh_type]}
                    </MenuItem>
                  )
                )}
              </TextField>
            );
          },
        },
        {
          header: "New Size",
          id: ":size",
          muiEditTextFieldProps: (props) =>
            muiEditTextFieldPropsStandard(props),
          editVariant: "select",
          editSelectOptions: ({ row }) => {
            // console.log(row.getValue(":endDepot") || row.getValue("endDepot"));
            // console.log(row.getValue(":veh_type") || row.getValue("veh_type"));
            const depot = row.getValue(":endDepot") || row.getValue("endDepot");
            const veh_type =
              row.getValue(":veh_type") || row.getValue("veh_type");

            const options =
              depot in depot_type_size_lookup &&
              veh_type in depot_type_size_lookup[depot]
                ? Object.keys(depot_type_size_lookup[depot][veh_type]).map(
                    (size) => ({
                      label:
                        veh_type == 1 ? (
                          <>
                            {unitFeet(size)} {unitWrapper(unitSmallMap[units])}
                          </>
                        ) : veh_type == 4 ? (
                          `Type ${size}`
                        ) : size == 14 ? (
                          "Transit"
                        ) : (
                          `Class ${size}`
                        ),
                      value: size,
                    })
                  )
                : [];
            return [
              { label: <>{unitWrapper("Current")}</>, value: 0 },
              ...options,
            ];
          },
          Cell: ({ row }) => {
            const veh_type = row.original.veh_type;
            return (
              <TextField
                name={`${row.original.key}:size`}
                fullWidth
                defaultValue="unaltered"
                select //a Select-type textfield
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
                }}
              >
                <MenuItem value={"unaltered"}>
                  {unitWrapper("Current")}
                </MenuItem>
                {Object.keys(
                  depot_type_size_lookup[row.original.endDepot][
                    row.original.veh_type
                  ]
                ).map((size) => (
                  <MenuItem key={size} value={size}>
                    {veh_type == 1 ? (
                      <>
                        {unitFeet(size)} {unitWrapper(unitSmallMap[units])}
                      </>
                    ) : veh_type == 4 ? (
                      `Type ${size}`
                    ) : size == 14 ? (
                      "Transit"
                    ) : (
                      `Class ${size}`
                    )}
                  </MenuItem>
                ))}
              </TextField>
            );
          },
        },
      ];
    },
    [validationErrors]
  );

  const table = useMaterialReactTable({
    ...materialReactTableOptions(),
    data: groupData,
    columns,
    enableEditing: true,
    editDisplayMode: "table",
    getRowId: (row) => row.key,
    enablePagination: false, //disabling pagination seems to remove the limit on number of rows displayed at once (tested for up to 14 rows)
    enableBottomToolbar: false,
    enableTopToolbar: false,
  });

  return <MaterialReactTable table={table} />;
}
