import MaterialTable, { MTableToolbar } from "@material-table/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { memo, useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { DataContext } from "../../../contexts/dataContext";
import { localDb } from "../../../contexts/localDb";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import {
  unitGGEMap,
  unitLargeAbbr,
  unitSmallMap,
  unitVolumeAbbr,
} from "../../../static/constants/systems_of_measurement";
import ResourceDetails from "../../secondary/resourceDetails";
import {
  currencyDisplay,
  currencySymbol,
  getUnits,
  unitFeet,
  unitGGE,
  unitGallons,
  unitMiles,
  unitMoney,
  unitPerGGE,
  unitPerGallon,
  unitPerMile,
  unitPerMoney,
} from "../../secondary/unitConversions";
import { Icons, roundNumber, unitWrapper } from "../../utils";
import { financialChipMap as chipMap } from "../pages/financialAnalysis";

const centeredCellStyle = {
  textAlign: "center",
  paddingRight: "calc(42px + 1em)",
}; //42px is the offset caused by the "arrow_downward" icon (26px) + the paddingRight of the cell header (16px) + a bit of white space to the right of the arrow_downward icon (1em)

const MemoizedFinancialAnalysisTable = memo(
  ({
    vehicles,
    dataFetchError,
    setVehicleChipData,
    setChargerChipData,
    setEnergyChipData,
    energyChipData,
    chargerChipData,
    vehicleChipData,
    selectedChip,
    setSelectedChip,
  }) => {
    const { accessRights } = useContext(DataContext);
    const { snackBarElement } = useContext(SnackBarContext);
    const [editRow, setEditRow] = useState({});
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [isDuplicateView, setIsDuplicateView] = useState(false); // used to determine if a cancelled edit should delete the final row or not

    const units = getUnits();

    const fuelEfficiencyUnits = (fuelType) =>
      fuelType != "CNG" && units == 2
        ? "mpg"
        : `${unitLargeAbbr[units]}/${
            fuelType == "CNG" ? unitGGEMap[units] : unitVolumeAbbr[units]
          }`;

    /**
     * todo: this editableFields should be split off into it's own component (with editRow being it's own local state).
     * @returns {jsx} the edit row dialog box pop-up
     */
    function editableFields() {
      const conventionalVehicleEditableColumn = [
        {
          title: "Fuel",
          name: "fuel",
          type: "fuel",
          value: editRow.fuel,
          props: {
            value: editRow.fuel, //overwrite the default value prop, since the value is not a number, isNaN would always fail
            select: true,
            children: Object.entries(TYPE_STRINGS.FUEL_TYPE).map(
              ([value, label]) => <MenuItem value={value}>{label}</MenuItem>
            ),
          },
        },
        {
          title: "Vehicle Unit Cost",
          name: `${editRow?.fuel?.toLowerCase()}_veh_cost`,
          type: "currency",
          value: editRow?.[`${editRow?.fuel?.toLowerCase()}_veh_cost`],
        },
        {
          title: `Fuel Efficiency (${fuelEfficiencyUnits(editRow?.fuel)})`,
          name: `${editRow?.fuel?.toLowerCase()}_veh_mpg`,
          type: "mpg",
          value: editRow?.[`${editRow?.fuel?.toLowerCase()}_veh_mpg`],
          props: { InputProps: { inputProps: { min: 0, step: 0.1 } } },
        },
        {
          title: `Maintenance (${currencySymbol()}/${unitLargeAbbr[units]})`,
          name: `maintenanceVeh${editRow?.fuel}`,
          type: "currencyPerMile",
          value: editRow?.[`maintenanceVeh${editRow?.fuel}`],
        },
      ];

      const bevVehicleEditableColumn = [
        {
          title: "Unit Cost",
          name: "bev_veh_cost",
          type: "currency",
          value: editRow.bev_veh_cost,
        },
        {
          title: "Incentives",
          name: "incentiveVeh",
          type: "currency",
          value: editRow.incentiveVeh,
        },
        {
          title: `Maintenance (${currencySymbol()}/${
            unitLargeAbbr[units]
          }) (BEV)`,
          name: "maintenanceVehBEV",
          type: "currencyPerMile",
          value: editRow.maintenanceVehBEV,
        },
        {
          title: "Battery Replacement Cost",
          name: "batteryRepCost",
          type: "currency",
          value: editRow.batteryRepCost,
        },
        {
          title: "Battery Resale Value",
          name: "batteryResValue",
          type: "currency",
          value: editRow.batteryResValue,
        },
      ];

      const chargerEditableCol = [
        {
          title: "Hardware Cost",
          name: "cost",
          type: "currency",
          value: editRow.cost,
        },
        {
          title: `maintenance (${currencySymbol()}/year)`,
          name: "chargerOM",
          type: "currency",
          value: editRow.chargerOM,
        },
        {
          title: "Installation and Commissioning Cost",
          name: "incCost",
          type: "currency",
          value: editRow.incCost,
        },
        {
          title: "Incentives",
          name: "incentiveCharger",
          type: "currency",
          value: editRow.incentiveCharger,
        },
      ];

      const iucEditableCol = [
        {
          title: "Upgrade Cost",
          name: "infraUpCost",
          type: "currency",
          value: editRow.infraUpCost,
        },
      ];

      const energyEditableCol = [
        // {
        //   title: "Infrastructure Upgrade Cost",
        //   name: "infraUpCost",
        //   type: "currency",
        //   value: editRow.infraUpCost,
        // },
        // {
        //   title: `Electricity Cost for Energy Charges (${currencySymbol()}/kWh)`,
        //   name: "elecCostEnergy",
        //   type: "currency",
        //   value: editRow.elecCostEnergy,
        // },
        // {
        //   title: `Electricity Cost for Demand Charges (${currencySymbol()}/kW)`,
        //   name: "elecCostDemand",
        //   type: "currency",
        //   value: editRow.elecCostDemand,
        // },
        // {
        //   title: `Grid Emissions - CO2e (${unitMassMap[units]}/kWh)`,
        //   name: "gridEmission",
        //   type: "kgPerkWh",
        //   value: editRow.gridEmission,
        // },
        // {
        //   title: `Fuel Cost (Cost per ${unitVolumeMap[units]})`,
        //   name: "dieselCost",
        //   type: "currencyPerGallon",
        //   value: editRow.dieselCost,
        // },
      ];

      const emissionEditableCol = [
        {
          title: "CO2 (kg/kWh)",
          name: "gridEmission",
          type: "kgPerkWh",
          value: editRow.gridEmission,
        },
        {
          title: "NOx (g/kWh)",
          name: "NOXEmission",
          type: "kgPerkWh",
          value: editRow.NOXEmission,
        },
        {
          title: "SO2 (g/kWh)",
          name: "SO2Emission",
          type: "kgPerkWh",
          value: editRow.SO2Emission,
        },
      ];

      const fuelCostEditableColumn = [
        // {
        //   title: "Gas Cost",
        //   name: "gas_veh_cost",
        //   type: "currencyPerGallon",
        //   value: editRow.gas_veh_cost,
        // },
        // {
        //   title: "CNG Cost",
        //   name: "cng_veh_cost",
        //   type: "currencyPerGallon",
        //   value: editRow.cng_veh_cost,
        // },
        // {
        //   title: "PROP Cost",
        //   name: "prop_veh_cost",
        //   type: "currencyPerGallon",
        //   value: editRow.prop_veh_cost,
        // },
        // {
        //   title: "ICE Cost",
        //   name: "ice_veh_cost",
        //   type: "currencyPerGallon",
        //   value: editRow.ice_veh_cost,
        // },
        // {
        //   title: "BEV Cost",
        //   name: "bev_veh_cost",
        //   type: "numeric",
        //   value: editRow.bev_veh_cost,
        // },
      ];

      const columnToShow = [
        conventionalVehicleEditableColumn,
        bevVehicleEditableColumn,
        chargerEditableCol,
        iucEditableCol,
        energyEditableCol,
        emissionEditableCol,
        fuelCostEditableColumn,
      ];

      function typeGenerator(fieldType) {
        switch (fieldType) {
          case "currency":
          case "currencyPerMile":
          case "currencyPerGallon":
          case "mpg":
          case "kgPerkWh":
            return "number";
          default:
            return "text";
        }
      }

      function getChipData() {
        switch (selectedChip) {
          case 1:
          case 2:
            return [...vehicleChipData];
          case 3:
            return [...chargerChipData];
          case 4:
          case 5:
          case 6:
            return [...energyChipData];
          // case 7:
          //   return [...fuelCostChipData];
        }
      }

      function defaultValue(element) {
        switch (element.type) {
          case "currencyPerMile":
            return roundNumber(unitMoney(unitPerMile(element.value)));
          case "currencyPerGallon":
            return roundNumber(unitMoney(unitPerGallon(element.value)));
          case "mpg":
            return roundNumber(
              unitMiles(
                editRow?.fuel == "CNG"
                  ? unitPerGGE(element.value)
                  : unitPerGallon(element.value)
              )
            );
          case "currency":
            return roundNumber(unitMoney(element.value));
          case "kgPerkWh":
            return roundNumber(element.value, 5);
          case "fuel":
            return element.value || "";
          default:
            return element.value;
        }
      }

      /**
       * @param {React.ChangeEvent<HTMLInputElement>} e
       * @param {String} type
       */
      function handleFieldUpdate(e, type) {
        let attr = e.target.name;
        switch (type) {
          case "currencyPerMile":
            editRow[attr] = unitPerMoney(unitMiles(parseFloat(e.target.value)));
            break;
          case "currencyPerGallon":
            editRow[attr] = unitPerMoney(
              unitGallons(parseFloat(e.target.value))
            );
            break;
          case "mpg":
            editRow[attr] = unitPerMile(
              editRow?.fuel === "CNG"
                ? unitGGE(parseFloat(e.target.value))
                : unitGallons(parseFloat(e.target.value))
            );
            break;
          case "kgPerkWh":
            editRow[attr] = parseFloat(e.target.value);
            break;
          case "currency":
            editRow[attr] = unitPerMoney(parseFloat(e.target.value));
            break;
          default:
            editRow[attr] =
              e.target.type === "number"
                ? parseFloat(e.target.value)
                : e.target.value;
            break;
        }
        setEditRow({ ...editRow });
      }

      function failedDuplicateHandler() {
        snackBarElement?.current?.displayToast(
          "Vehicle Type/Size/Fuel combination must be unique!",
          "warning",
          5000
        );
        return;
      }

      function updateValue(e) {
        e.preventDefault();
        let temp = getChipData();
        const index = temp.findIndex(
          (row) => row.tableData.uuid == editRow.tableData.uuid
        );
        temp[index] = editRow;
        if (selectedChip === 1) {
          if (
            temp.filter(
              (row) => row.model == editRow.model && row.fuel == editRow.fuel
            ).length >= 2
          )
            return failedDuplicateHandler();

          setVehicleChipData(temp);
          setIsDuplicateView(false);
          localDb.financial.update("data", { "value.vehicle": temp });
        } else if (selectedChip === 2) {
          //vehicleBEV
          //makes sure to update potential hidden rows created in previous chipView (without overwritting the values set in prior chip)
          //note: since the "runTCO" combining ConventionalVehicleRows stuff is done, removed the below mapping (since only the first instance of the ice matters in runTCO)
          // temp = temp.map((row) => {
          //   if (row.model == editRow.model) {
          //     let updateDuplicateModels = { ...editRow };
          //     columnToShow[0].forEach(
          //       ({ name }) => (updateDuplicateModels[name] = row[name])
          //     );
          //     updateDuplicateModels.tableData = row.tableData;
          //     return updateDuplicateModels;
          //   } else return row;
          // });
          setVehicleChipData(temp);
          localDb.financial.update("data", { "value.vehicle": temp });
        } else if (selectedChip === 3) {
          //charger
          setChargerChipData(temp);
          localDb.financial.update("data", { "value.charger": temp });
        } else if (
          selectedChip === 4 ||
          // selectedChip === 5 ||
          selectedChip === 6
        ) {
          //energy
          setEnergyChipData(temp);
          localDb.financial.update("data", { "value.energy": temp });
        }

        setEditDialogOpen(false);
      }

      function handleClose() {
        if (selectedChip == 1 && isDuplicateView) {
          setVehicleChipData((vehicleChipData) =>
            vehicleChipData.slice(0, vehicleChipData.length - 1)
          );
          setIsDuplicateView(false);
        }
        setEditDialogOpen(false);
      }

      return (
        <Dialog
          component="form"
          onSubmit={updateValue}
          open={editDialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">
            Edit {chipMap[selectedChip]} details here
          </DialogTitle>
          <br />
          <DialogContent>
            <Grid
              container
              component={Container}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 5 }}
            >
              {columnToShow[selectedChip - 1].map((el) => (
                <Grid key={`Chip${selectedChip}_${el.name}`} item xs={6}>
                  <TextField
                    required
                    name={el.name}
                    label={el.title}
                    variant="outlined"
                    fullWidth
                    type={typeGenerator(el.type)}
                    InputProps={{
                      startAdornment: el.type.startsWith("currency") && (
                        <InputAdornment position="start">
                          {currencySymbol()}
                        </InputAdornment>
                      ),
                      inputProps: el.type.startsWith("currency") && {
                        min: 0,
                        step: 0.01,
                      },
                    }}
                    value={!isNaN(el.value) ? defaultValue(el) : ""}
                    onChange={(e) => handleFieldUpdate(e, el.type)}
                    {...el.props}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Cancel
            </Button>
            <Button type="submit">Done</Button>
          </DialogActions>
        </Dialog>
      );
    }

    const vehicleBEVColumn = [
      {
        title: "Vehicle Model",
        field: "model",
        render: (rowData) => (
          <VehicleDataComponent rowData={rowData} vehicles={vehicles} />
        ),
      },
      {
        title: (
          <>
            <br />
            Unit&nbsp;Cost
            <br />
            <br />
          </>
        ),
        field: "bev_veh_cost",
        render: (rowData) => currencyDisplay(rowData.bev_veh_cost),
      },
      {
        title: (
          <>
            Incentive
            <br />
            (per&nbsp;Vehicle)
          </>
        ),
        field: "incentiveVeh",
        render: (rowData) => currencyDisplay(rowData.incentiveVeh),
        cellStyle: centeredCellStyle,
      },
      {
        title: (
          <>
            Maintenance
            <br />({currencySymbol()}/{unitLargeAbbr[units]})
          </>
        ),
        field: "maintenanceVehBEV",
        render: (rowData) =>
          currencyDisplay(unitPerMile(rowData.maintenanceVehBEV)),
        cellStyle: centeredCellStyle,
      },
      {
        title: "Battery Replacement Cost",
        field: "batteryRepCost",
        render: (rowData) => currencyDisplay(rowData.batteryRepCost),
        cellStyle: centeredCellStyle,
      },
      {
        title: "Battery Resale Value",
        field: "batteryResValue",
        render: (rowData) => currencyDisplay(rowData.batteryResValue),
        cellStyle: centeredCellStyle,
      },
    ];

    const conventionalVehicleColumn = [
      {
        title: "Vehicle Type",
        field: "type",
        render: (rowData) => TYPE_STRINGS.VEHICLE_TYPE[parseInt(rowData.type)],
      },
      {
        title: "Vehicle Size",
        field: "size",
        render: (rowData) =>
          rowData.type == 1 ? (
            <>
              {unitFeet(rowData.size)} {unitWrapper(unitSmallMap[units])}
            </>
          ) : rowData.type == 4 ? (
            //if selected vehicle type is a schoolbus,remove the ft
            `Type ${rowData.size}`
          ) : rowData.size == 14 ? (
            "Transit"
          ) : (
            `Class ${rowData.size}`
          ),
      },
      {
        title: "Fuel",
        field: "fuel",
        // lookup: fuelLookup,
        render: (rowData) => TYPE_STRINGS.FUEL_TYPE[rowData.fuel],
      },
      {
        title: "Vehicle Unit Cost",
        // field: "ice_veh_cost",
        render: (rowData) =>
          currencyDisplay(rowData[`${rowData?.fuel?.toLowerCase()}_veh_cost`]),
        cellStyle: centeredCellStyle,
        sorting: false,
      },
      {
        title: "Fuel Efficiency",
        // field: "efficiency",
        render: (rowData) => (
          <>
            {roundNumber(
              unitMiles(
                rowData.fuel == "CNG"
                  ? unitPerGGE(rowData.cng_veh_mpg)
                  : unitPerGallon(
                      rowData[`${rowData?.fuel?.toLowerCase()}_veh_mpg`]
                    )
              )
            )}{" "}
            {unitWrapper(fuelEfficiencyUnits(rowData?.fuel))}
          </>
        ),
        cellStyle: centeredCellStyle,
        sorting: false,
      },
      {
        title: (
          <>
            <br />
            Maintenance
            <br />({currencySymbol()}/{unitLargeAbbr[units]})
          </>
        ),
        // field: "maintenanceVehICE",
        render: (rowData) =>
          currencyDisplay(
            unitPerMile(rowData[`maintenanceVeh${rowData?.fuel}`])
          ),
        cellStyle: centeredCellStyle,
        sorting: false,
      },
    ];

    const chargerColumn = [
      { title: "Model", field: "model" },
      {
        title: "Rating",
        field: "rating",
        render: (rowData) => (
          <>
            {rowData.rating} {unitWrapper("kW")}
          </>
        ),
      },
      {
        title: "Hardware Cost",
        field: "cost",
        render: (rowData) => currencyDisplay(rowData.cost),
      },
      {
        title: (
          <>
            Incentives
            <br />
            (per Charger)
          </>
        ),
        field: "incentiveCharger",
        render: (rowData) => currencyDisplay(rowData.incentiveCharger),
        cellStyle: centeredCellStyle,
      },
      {
        title: `Maintenance (${currencySymbol()}/year)`,
        field: "chargerOM",
        render: (rowData) => currencyDisplay(rowData.chargerOM),
        cellStyle: centeredCellStyle,
      },
      {
        title: "Installation & Commissioning Cost",
        field: "incCost",
        render: (rowData) => currencyDisplay(rowData.incCost),
        cellStyle: centeredCellStyle,
      },
    ];

    const iucColumn = [
      {
        title: (
          <>
            <br />
            Infrastructure Upgrade Cost
            <br />
            <br />
          </>
        ),
        field: "infraUpCost",
        render: (rowData) => currencyDisplay(rowData.infraUpCost),
      },
    ];

    const energyColumn = [
      // {
      //   title: "Infrastructure Upgrade Cost",
      //   field: "infraUpCost",
      //   render: (rowData) => <>{currencyDisplay(rowData.infraUpCost)}</>,
      // },
      // {
      //   title: `Electricity Cost for energy charges (${currencySymbol()}/kWh)`,
      //   field: "elecCostEnergy",
      //   render: (rowData) => <>{currencyDisplay(rowData.elecCostEnergy)}</>,
      // },
      // {
      //   title: `Electricity cost for demand charges (${currencySymbol()}/kW)`,
      //   field: "elecCostDemand",
      //   render: (rowData) => <>{currencyDisplay(rowData.elecCostDemand)}</>,
      // },
      // {
      //   title: (
      //     <>
      //       Fuel Cost
      //       <br />
      //       (Cost/{unitVolumeMap[units]})
      //     </>
      //   ),
      //   field: "dieselCost",
      //   render: (rowData) => (
      //     <>{currencyDisplay(unitPerGallon(rowData.dieselCost))}</>
      //   ),
      // },
      // {
      //   title: `Grid Emission - CO2 Equivalent (${unitMassMap[units]}/kWh)`,
      //   type: "numeric",
      //   render: (rowData) => (
      //     <>{roundNumber(unitTon(rowData.gridEmission), 4)}</>
      //   ),
      // },
    ];

    const emissionColumn = [
      {
        title: (
          <>
            <br />
            CO2 Emissions
            <br />
            <br />
          </>
        ),
        field: "gridEmission",
        render: (rowData) => (
          <>
            {roundNumber(rowData.gridEmission, 5)} {unitWrapper("kg/kWh")}
          </>
        ),
      },
      {
        title: "NOx Emissions",
        field: "NOXEmission",
        render: (rowData) => (
          <>
            {roundNumber(rowData.NOXEmission, 5)} {unitWrapper("g/kWh")}
          </>
        ),
      },
      {
        title: "SO2 Emissions",
        field: "SO2Emission",
        render: (rowData) => (
          <>
            {roundNumber(rowData.SO2Emission, 5)} {unitWrapper("g/kWh")}
          </>
        ),
      },
    ];

    const fuelCostColumn = [
      // {
      //   title: "Gas Cost",
      //   field: "gas_veh_cost",
      //   type: "numeric",
      //   cellStyle: { textAlign: "center" },
      // },
      // {
      //   title: "CNG Cost",
      //   field: "cng_veh_cost",
      //   type: "numeric",
      //   cellStyle: { textAlign: "center" },
      // },
      // {
      //   title: "PROP Cost",
      //   field: "prop_veh_cost",
      //   type: "numeric",
      //   cellStyle: { textAlign: "center" },
      // },
      // {
      //   title: "ICE Cost",
      //   field: "ice_veh_cost",
      //   type: "numeric",
      //   cellStyle: { textAlign: "center" },
      // },
      // {
      //   title: "BEV Cost",
      //   field: "bev_veh_cost",
      //   type: "numeric",
      //   cellStyle: { textAlign: "center" },
      // },
    ];

    const addColumnLookup = [
      { data: vehicleChipData, columns: conventionalVehicleColumn },
      {
        data:
          //removes duplicate models created in conventional vehicle view (selectedChip==1)
          vehicleChipData.filter(
            (veh_1, index) =>
              vehicleChipData.findIndex(
                (veh_2) => veh_1.model == veh_2.model
              ) == index
          ),
        columns: vehicleBEVColumn,
      },
      { data: chargerChipData, columns: chargerColumn },
      { data: energyChipData, columns: iucColumn },
      { data: energyChipData, columns: energyColumn },
      { data: energyChipData, columns: emissionColumn },
      { data: [], columns: fuelCostColumn }, //fuelCostChipData
    ];

    return (
      <>
        {/* The material table for chips 1,2,3,4, & 6 */}
        <MaterialTable
          title={`${chipMap[selectedChip]} Inputs`}
          columns={addColumnLookup[selectedChip - 1].columns}
          data={addColumnLookup[selectedChip - 1].data}
          icons={Icons()}
          // parentChildData={(row, rows) => rows.find(r => r.model ===)}
          localization={{
            body: {
              emptyDataSourceMessage: dataFetchError ? (
                "No records to display"
              ) : (
                <>
                  <CircularProgress />
                  <br />
                  Loading...
                </>
              ),
            },
            toolbar: {
              searchPlaceholder: "Filter",
              searchTooltip: "Filter",
            },
          }}
          options={{
            paging: false,
            pageSize: 20,
            showTextRowsSelected: false,
            headerStyle: { textAlign: "center" },
            search: false,
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ margin: "4px 10px" }}>
                  <Stack direction="row" spacing={1}>
                    {chipMap.slice(1, 8).map((value, index) => (
                      <Chip
                        label={value}
                        key={`${value}_chip`}
                        variant={
                          index + 1 === selectedChip ? "filled" : "outlined"
                        }
                        onClick={() => setSelectedChip(index + 1)}
                      />
                    ))}
                  </Stack>
                </div>
              </div>
            ),
          }}
          actions={[
            {
              icon: EditIcon,
              onClick: (event, rowData) => {
                setEditRow(rowData);
                setEditDialogOpen(true);
              },
              position: "row",
              tableLayout: "fixed",
              disabled:
                !accessRights.analysis.create_financial_and_emissions_analysis,
            },
            {
              icon: ContentCopyIcon,
              tooltip: "Add Fuel Type",
              position: "row",
              onClick: (event, rowData) => {
                //set the ID of the row to be duplicated, for use in handleDuplicateRow()
                const duplicateRow = { ...rowData };
                duplicateRow.tableData = {
                  //note: id is SUPER DEPRECATED. as in: if you use it in new code, it will probably break something
                  /** @deprecated */
                  id: addColumnLookup[selectedChip - 1].data.length,
                  uuid: uuidv4(),
                  checked: true,
                };
                setIsDuplicateView(true);
                setVehicleChipData([...vehicleChipData, duplicateRow]);
                setEditRow(duplicateRow);
                setEditDialogOpen(true);
              },
              hidden: selectedChip != 1,
            },
          ]}
          editable={{
            onRowDelete:
              //can only delete conventional vehicle rows
              (selectedChip === 1 || undefined) &&
              ((deletedRow) =>
                new Promise((resolve, reject) => {
                  const originalData = addColumnLookup[selectedChip - 1].data;
                  if (
                    originalData.length - 1 ===
                    originalData.filter((row) => row.model !== deletedRow.model)
                      .length
                  ) {
                    //if the row to be deleted is the LAST vehicle of that specific model, users CANNOT delete the row
                    snackBarElement?.current?.displayToast(
                      "Cannot delete the last instance of a unique vehicle size/type combination",
                      "warning",
                      5000
                    );
                    reject();
                    return;
                  }
                  //else
                  /// cant use tableData.uuid for the delete, because apparently, material-table/core doesn't include it
                  //// so, the next most unique option is the model+fuel combo
                  const newData = originalData.filter(
                    (row) =>
                      row.model !== deletedRow.model ||
                      row.fuel !== deletedRow.fuel
                  );
                  setVehicleChipData(newData);
                  localDb.financial.update("data", {
                    "value.vehicle": newData,
                  });
                  resolve();
                })),
          }}
        />
        {/* the edit dialog box contents */}
        {editableFields()}
      </>
    );
  }
);

/** This react component the pop-up that appears when a user clicks the little "i" icon
 * next to the vehicle model in Battery Electric Vehicle table view
 * @param {object} props
 * @param {object} props.rowData
 * @param {object[]} props.vehicles
 * @returns {jsx} the dialog box pop-up
 */
export function VehicleDataComponent({ rowData, vehicles }) {
  const [open, setOpen] = useState(false);
  const dialogData = vehicles?.find((x) => x.model === rowData?.model);

  return (
    <div>
      <div sx={{ alignItems: "center", display: "flex" }}>
        {rowData.model}
        <IconButton
          size="small"
          title="info"
          onClick={(e) => setOpen(true)}
          disabled={!dialogData}
        >
          <InfoIcon fontSize="1" />
        </IconButton>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">Resource Details</DialogTitle>
        <DialogContent>
          <ResourceDetails dialogData={dialogData} />
        </DialogContent>
        <DialogActions>
          {/* <Button disabled>More Details</Button> */}
          <Button onClick={() => setOpen(false)} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MemoizedFinancialAnalysisTable;
